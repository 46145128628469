<template>
  <section v-if="!loading">

    <section v-if="!user.authenticated">
      <div class="box-content">
          <div v-if="error">
            <i class="fal fa-exclamation-circle"></i>
            {{ $t("ERROR.COULD_NOT_USE_INVITE") }}    
          </div>
          <div v-if="!error">
            <h5>{{ $t('FUNCTIONBOX.TITLE')}} {{$t('ACTIVATED')}}</h5>
          </div>
      </div>
    </section>
    
    <section v-if="user.authenticated">
          <div v-if="error">
            <i class="fal fa-exclamation-circle"></i>
            {{ $t("ERROR.COULD_NOT_USE_INVITE") }}    
          </div>
          <div v-if="!error">
            <h5>{{ $t('FUNCTIONBOX.TITLE')}} {{$t('ACTIVATED')}}</h5>
          </div>
    </section>

  </section>
</template>
<script>
export default {
  components: {
  },
  props: ["code"],
  data() {
    return {
      error: false,
      loading: true
    };
  },
  methods: {
    processActivation: function() {
      this.loading = true;
      let self = this;
      this.$http
       .post(
          this.user.hostname + "/administration/functionbox/verify",
          {
            verification_code: this.code
          }
        )
        .then(function() {
          self.loading = false;
          self.error = false;
        })
        .catch(function() {
          self.error = true;
          self.loading = false;
        });
    }
  },
  computed: {
    logoUrl(){
      return this.user.hostname + "/logo";
    },
    user() {
      return this.$store.state.user;
    }
  },
  mounted: function() {
    this.processActivation();
  },
};
</script>