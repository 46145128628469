var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("section", [
        !_vm.user.authenticated
          ? _c("section", [
              _c("div", { staticClass: "box-content" }, [
                _vm.error
                  ? _c("div", [
                      _c("i", { staticClass: "fal fa-exclamation-circle" }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("ERROR.COULD_NOT_USE_INVITE")) +
                          "    \n        "
                      ),
                    ])
                  : _vm._e(),
                !_vm.error
                  ? _c("div", [
                      _c("h5", [
                        _vm._v(
                          _vm._s(_vm.$t("FUNCTIONBOX.TITLE")) +
                            " " +
                            _vm._s(_vm.$t("ACTIVATED"))
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm.user.authenticated
          ? _c("section", [
              _vm.error
                ? _c("div", [
                    _c("i", { staticClass: "fal fa-exclamation-circle" }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("ERROR.COULD_NOT_USE_INVITE")) +
                        "    \n        "
                    ),
                  ])
                : _vm._e(),
              !_vm.error
                ? _c("div", [
                    _c("h5", [
                      _vm._v(
                        _vm._s(_vm.$t("FUNCTIONBOX.TITLE")) +
                          " " +
                          _vm._s(_vm.$t("ACTIVATED"))
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }